.services-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding: 50px;
  gap: 10px;
  width: 100vw;
}

.services-grid {
  width: 55vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 20px;
  margin-top: 40px;
  justify-content: center;
}

.services-grid-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0px 20px rgba(18, 18, 18, 0.2);
  border-radius: 10px;
  padding: 30px 20px;
  width: 18vw;
  background-color: white;

  transition: all 400ms ease;
}

.services-grid-item-top-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 270px;
  width: 95%;
  gap: 5px;
  margin-bottom: 25px;
}

.servives-grid-item-image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 90%;
}

.services-grid-item-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services-grid-item-header {
  text-align: center;
  font-weight: 500;
  font-size: 27px;
}

.services-grid-item-body {
  text-align: center;
  font-size: 18px;
  color: var(--secondaryColorColor);
}

@media only screen and (max-width: 1023px) {
  .services-container {
    padding: 40px;
  }

  .service-grid-container {
    display: flex;
    align-items: center;
  }

  .service-grid-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: transparent;
    border: none;
    width: 40px;
    aspect-ratio: 1;
  }

  .service-grid-button.prev {
    left: 10px;
  }

  .service-grid-button.next {
    right: 10px;
  }

  .services-grid {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100vw;
    padding: 20px 17vw;
    justify-content: flex-start;
    margin-top: 10px;
    position: relative;
  }

  .services-grid-item {
    display: none;
    padding: 30px 10px;
    width: 66vw;
    height: 420px;
  }

  .services-grid-item.active {
    display: flex;
  }

  .services-grid-item.next,
  .services-grid-item.prev {
    display: flex;
    position: absolute;
  }

  .services-grid-item.next {
    transform: translateX(200%);
  }

  .services-grid-item.prev {
    transform: translateX(-250%);
  }
}
