.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 84px;
  padding: 15px 100px 10px 100px;
  z-index: 50;
  background-color: rgb(255, 255, 255);

  transition: all 300ms ease-in-out;
}

.navbar-container.scrolled {
  box-shadow: 0 0 2em 0 rgba(0, 0, 0, 0.4);
  /* background-color: rgb(226, 229, 218); */
}

.navbar-logo-button {
  height: 48px;
  width: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.navbar-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.navbar-navigation {
  display: flex;
  gap: 31px;
}

.navbar-navigation-button {
  background-color: transparent;
  border: none;
  font-size: 17px;
  cursor: pointer;
  color: var(--secondaryColor);

  transition: all 300ms ease-in-out;
}

.navbar-navigation-button:hover {
  scale: 1.04;
  color: var(--primaryColor);
}

.navbar-navigation-button.blur {
  opacity: 0.4;
}

/* ======= menu button ======= */
.menu {
  display: none;
  background-color: transparent;
  color: var(--fontColor);
  cursor: pointer;
  border: none;

  z-index: 99;
}

.hidden {
  visibility: hidden;
}

@media only screen and (max-width: 1023px) {
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-container {
    padding: 22px 20px 15px 20px;
    height: 70px;
  }

  .navbar-logo-button {
    height: 32px;
    width: 100px;
  }

  .navbar-navigation {
    padding: 25px 15px;
    background-color: transparent;
    position: fixed;
    border-radius: 1rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    align-items: center;
    gap: 20px;

    top: -50%;
    left: 0;
    right: 0;
    width: 100vw;
    height: auto;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.5);

    transition: all 500ms ease-in-out;
  }

  .navbar-navigation.scrolled {
    padding: 20px 15px;
    margin-top: 0px;
  }

  .navbar-navigation.active {
    background-color: rgba(18, 18, 18, 0.7);
    backdrop-filter: blur(10px);
    top: 0%;
  }

  .navbar-navigation-button {
    color: white;
    width: 50%;
    padding: 5px;
  }

  .navbar-navigation-button.blur {
    opacity: 1;
  }
}
