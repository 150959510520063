.experience-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px;
    gap: 10px;
    width: 100vw;
    position: relative;
    background-color: var(--thirdColor);
    border-top: 1px solid black;
}

.experience-company-zone {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    justify-content: center;
    padding: 20px;
}

.experience-company-grid-box {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}

.company-zone-gird-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.experience-company-box {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    position: relative;
    border-radius: 10px;
    z-index: 1;
}

.experience-company-box-image {
    position: absolute;
    width: 80%;
    height: 80%;
    object-fit: contain;
}

@media only screen and (max-width: 1023px) {
    .experience-container {
        padding: 30px 0;
        min-height: 40vh;
    }

    .experience-grid-container {
        display: flex;
        align-items: center;
        height: 230px;
    }

    .experience-company-zone {
        width: 100vw;
        display: flex;
        flex-wrap: nowrap;
        margin-top: 15px;
        overflow: hidden;
        justify-content: flex-start;
        position: relative;
    }

    .experience-company-grid-box {
        background-color: white;
        border-radius: 4px;
        padding: 7px;
    }

    .experience-company-box {
        display: none;
        width: 30vw;

        transition: all 400ms ease;
    }

    .experience-company-box.active {
        display: flex;
    }

    .experience-company-box.next,
    .experience-company-box.nextnext,
    .experience-company-box.prev,
    .experience-company-box.prevprev {
        display: flex;
        position: absolute;
    }

    .experience-company-box.next {
        transform: translateX(300%);
    }

    .experience-company-box.nextnext {
        transform: translateX(400%);
    }

    .experience-company-box.prev {
        transform: translateX(-300%);
    }

    .experience-company-box.prevprev {
        transform: translateX(-400%);
    }

    .experience-company-box-image {
        width: 90%;
        height: 90%;
    }
}
