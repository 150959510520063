.aboutus-container {
  background-color: var(--thirdColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid black;
  width: 100vw;
}

.aboutus-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 10px;
}

.aboutus-seperator {
  width: 200px;
  height: 3px;
  border-radius: 10px;
  background-color: var(--primaryColor);
}

.aboutus-header {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.aboutus-aboutus-text-box {
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: center;
  gap: 10px;
}

.aboutus-aboutus-text {
  font-size: 20px;
  color: var(--secondaryColor);
}

.aboutus-box.mission {
  border-bottom: 1px solid black;
}

@media only screen and (max-width: 1023px) {
  .aboutus-box {
    padding: 20px 40px;
  }

  .aboutus-aboutus-text-box {
    width: 100%;
  }

  .aboutus-aboutus-text {
    font-size: 18px;
    color: var(--secondaryColor);
  }
}
