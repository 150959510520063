.contactus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  gap: 10px;
  width: 100vw;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid black;
}

.contactus-mail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactus-image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43%;
  height: auto;
  overflow: hidden;
}

.contactus-image {
  height: 100%;
  width: 100%;
  transform: translateY(2px);

  scale: 0.95;
  filter: blur(10px);
  transition: all 500ms ease-in-out;
}

.contactus-image.showen {
  scale: 1.05;
  filter: blur(0px);
}

.contactus-form {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 35%;
  gap: 3px;
  align-items: center;
}

.contactus-input-field {
  width: 100%;
  border: none;
  border-bottom: 3px solid var(--primaryColor);
  color: var(--secondaryColor);
  margin: 7px 0;
  padding: 14px 32px;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.8px;
  transition: all 300ms ease-in-out;
  color: black;
  font-family: "Centra", sans-serif;
}

.contactus-input-field:focus {
  color: black;
  outline: none;
}

.contactus-input-field::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondaryColor);
  transition: all 300ms ease-in-out;
}

.contactus-input-field:focus::placeholder {
  opacity: 0.5;
  scale: 0.98;
}

/* CSS for the button */
.contactus-form-button {
  box-shadow: 0 0 0 0.1em inset var(--primaryColor);
  --_g: linear-gradient(var(--primaryColor) 0 0) no-repeat;

  background: var(--_g) calc(var(--_p, 0%) - 100%) 0%,
    var(--_g) calc(200% - var(--_p, 0%)) 0%,
    var(--_g) calc(var(--_p, 0%) - 100%) 100%,
    var(--_g) calc(200% - var(--_p, 0%)) 100%;
  background-size: 50.5% calc(var(--_p, 0%) / 2 + 0.5%);

  font-size: 30px;
  cursor: pointer;
  padding: 0.2em 1em;
  font-weight: 500;
  border: none;
  border-radius: 20px;
  color: var(--secondaryColor);
  margin-top: 20px;
  margin-bottom: 22px;
  width: 50%;

  outline-offset: 0.1em;
  transition: background-size 0.4s, background-position 0s 0.4s;
}

.contactus-form-button:hover {
  --_p: 100%;
  color: white;
  transition: all 0.4s, background-size 0s;
}

.contactus-form-button:active {
  box-shadow: 0 0 9e9Q inset #0009;
  background-color: var(--primaryColor);
  color: #fff;
}

@media only screen and (max-width: 1023px) {
  .contactus-mail {
    margin-top: 10px;
  }

  .contactus-form {
    width: 100%;
  }

  .contactus-input-field {
    border-radius: 0;
  }

  .contactus-form-button {
    font-size: 24px;
    padding: 0.3em 1em;
    margin-top: 15px;
    margin-bottom: 20px;
    width: auto;
  }

  .contactus-input-field:focus::placeholder {
    opacity: 0.5;
    scale: 0.95;
  }
}
