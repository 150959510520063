.footer-container {
  background-color: var(--primaryColor);
  width: 100vw;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 100px;
}

.footer-left {
  display: flex;
  flex: 1.5;
  gap: 100px;
}

.footer-right {
  justify-content: flex-end;
  display: flex;
  flex: 1;
  gap: 3px;
}

.footer-box-big {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.footer-box {
  display: flex;
  align-items: center;
  gap: 7px;
  flex: 1;
}

.footer-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-text {
  color: white;
  font-size: 14px;
}

.footer-box.button {
  background-color: transparent;
  border: none;
  cursor: pointer;

  transition: all 300ms ease-in-out;
}

.footer-box.button:hover {
  scale: 1.03;
}

.social-link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  width: 44px;
  aspect-ratio: 1;
  padding-left: -100px;
  background-color: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transition: 0.25s ease-in-out;
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.social-link-button:hover {
  color: var(--primaryColor);
}

.social-link-alternate-image {
  width: 100%;
  justify-self: 100%;
  scale: 0.6;
  transition: all 0.25s ease-in-out;

  color: brown;
}

.social-link-alternate-image:hover {
  fill: var(--primaryColor);
}

@media only screen and (max-width: 1023px) {
  .footer-container {
    padding: 10px 15px;
    flex-direction: column;
    height: auto;
    gap: 10px;
  }

  .footer-left {
    justify-content: space-between;
    gap: 10px;
  }

  .footer-box-big {
    flex: 0;
  }

  .footer-box {
    gap: 5px;
  }

  .footer-right {
    width: 75%;
    justify-content: space-evenly;
    gap: 0;
  }

  .footer-text {
    font-size: 11px;
  }

  .social-link-button {
    width: 40px;
  }
}
