/* ======= Custom Font ======= */
@font-face {
  font-family: Proxima;
  src: url("./assets/font/ProximaNovaBlack.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/* ======= App colors ======= */
:root {
  --primaryColor: #4cb748;
  --secondaryColor: #636466;
  --thirdColor: #f0f5e0;
}

/* ======= Default css ======= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  font-family: "Centra", sans-serif;
}

a {
  color: var(--fontColor);
  text-decoration: none;
  font-family: "Centra", sans-serif;
}

button {
  font-family: "Centra", sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.loading-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 99;
  position: absolute;
}

.loading-logo-box {
  width: 180px;
  aspect-ratio: 1;
}

.loading-logo-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.loading {
  width: 40px;
  aspect-ratio: 1;
  margin-bottom: 123px;
  border: 5px solid var(--primaryColor);
  border-top: 5px solid var(--secondaryColor);
  border-radius: 50%;
  animation: spin 1200ms linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-frame.faint {
  animation: faint 1000ms linear forwards;
}

@keyframes faint {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
